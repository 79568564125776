import { template as template_610d93a895854f4a94815a473edad75b } from "@ember/template-compiler";
const FKControlMenuContainer = template_610d93a895854f4a94815a473edad75b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
