import { template as template_7128d2792f4b4c32a64c9d1756a53efd } from "@ember/template-compiler";
const FKText = template_7128d2792f4b4c32a64c9d1756a53efd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
