import { template as template_0be1718f8c9c41299e2f2b2a7d3ac993 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_0be1718f8c9c41299e2f2b2a7d3ac993(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
