import { template as template_43537342cf4c4bf5a9ba7fdcb9c1cc3a } from "@ember/template-compiler";
const WelcomeHeader = template_43537342cf4c4bf5a9ba7fdcb9c1cc3a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
