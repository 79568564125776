import { template as template_534eba051d634985a4d6f8010a7bdf47 } from "@ember/template-compiler";
const FKLabel = template_534eba051d634985a4d6f8010a7bdf47(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
